.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*==================== GOOGLE FONTS ====================*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/*==================== VARIABLES CSS ====================*/

:root {
  --header-height: 3rem;
  /*========== Colors ==========*/
  /* Change favorite color */
  --hue-color: 230;
  /*Purple 250 - Green 142 - Blue 230 - Pink 340*/
  /* HSL color mode */
  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
  /*========== Font and typography ==========*/
  --body-font: 'Poppins', sans-serif;
  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /*========== Margenes Bottom ==========*/
  /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Font size for large devices */

@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*========== Variables Dark theme ==========*/

body.dark-theme {
  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

/*========== Button Dark/Light ==========*/

.nav_btns {
  display: flex;
  align-items: center;
}

.change-theme {
  font-size: 1.25rem;
  color: var(--title-color);
  margin: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover {
  color: var(--first-color);
}

/*==================== BASE ====================*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/

.section {
  padding: 2rem 0 4rem;
}

.section_title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section_subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
}

.section_title,
.section_subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/

.container {
  max-width: 800px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  /* background-color: hsl(var(--hue-color), 60%, 95%); */
  background-color: var(--body-color);
}

/*==================== NAV ====================*/

.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrolled {
  background-color: var(--input-color);
  transition: background-color 0.5s ease-in-out;
}

.nav_logo,
.nav_toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav_logo:hover {
  color: var(--first-color);
}

.nav_toggle {
  font-size: 1.1rem;
  cursor: pointer;
}

.nav_toggle:hover {
  color: var(--first-color);
}

@media screen and (max-width: 767px) {
  .nav_menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
}

.nav_list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav_link:hover {
  color: var(--first-color);
}

.nav_icon {
  font-size: 1.2rem;
}

.nav_close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}

.nav_close:hover {
  color: var(--first-color-alt);
}

/* show menu */

.show-menu {
  bottom: 0;
}

/* Active link */

.active-link {
  color: var(--first-color);
}

/* Change background header */

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/*==================== HOME ====================*/

.home_container {
  gap: 1rem;
}

.home_content {
  grid-template-columns: 0.4fr 4fr 1fr;
  padding-top: 3.5rem;
  align-items: center;
  column-gap: 2rem;
}

.home_social {
  display: grid;
  grid-template-columns: max-content;
  align-self: center;
  row-gap: 2rem;
}

.home_social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.home_social-icon:hover {
  color: var(--first-color-alt);
}

.home_blob {
  width: 200px;
  fill: var(--first-color);
}

.home_img {
  order: 1;
  justify-self: start;
}

.home_blob-img {
  height: 250px;
  width: 170px;
}

.home_data {
  justify-items: start;
}

.home_title-wrapper {
  display: grid;
  justify-items: start;
}

.home_title {
  font-size: var(--big-font-size);
}

.home_subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-75);
}

.home_description {
  margin-bottom: var(--mb-2);
  text-align: start;
}

.home_scroll {
  display: block;
  text-align: start;
}

.home_scroll-button {
  color: var(--first-color);
  transition: 0.3s;
}

.home_scroll-button:hover {
  transform: translateY(0.25rem);
}

.home_scroll-mouse {
  font-size: 2rem;
}

.home_scroll-arrow {
  font-size: 1.25rem;
}

/*==================== BUTTONS ====================*/

button {
  outline: none;
  border: none;
}

.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--first-color);
}

.button_icon {
  font-size: 1.25rem;
  margin-left: var(--mb-0-5);
  transition: 0.3s;
}

.button--white {
  background-color: #fff;
  color: var(--first-color);
}

.button--white:hover {
  background-color: #fff;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--small {
  padding: 0.75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: var(--first-color);
}

.button--link:hover {
  background-color: transparent;
  color: var(--first-color-alt);
}

/*==================== SKILLS ====================*/

.skills {
  padding-bottom: 0;
}

.skills_header {
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-2-5);
  cursor: pointer;
}

.skills_icon,
.skills_arrow {
  font-size: 2rem;
  color: var(--first-color);
}

.skills_icon {
  margin-right: var(--mb-0-75);
}

.skills_title {
  font-size: var(--h3-font-size);
}

.skills_subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.skills_arrow {
  margin-left: auto;
  transform: 0.4s;
}

.skills_list {
  row-gap: 1.5rem;
  padding-left: 2.7rem;
}

.skills_titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-0-5);
}

.skills_name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills_bar,
.skills_percentage {
  height: 5px;
  border-radius: 0.25rem;
}

.skills_bar {
  background-color: var(--first-color-lighter);
}

.skills_percentage {
  display: block;
  background-color: var(--first-color);
}

.skills_html {
  width: 90%;
}

.skills_css {
  width: 80%;
}

.skills_js {
  width: 70%;
}

.skills_react {
  width: 85%;
}

.skills_php {
  width: 70%;
}

.skills_node {
  width: 90%;
}

.skills_firebase {
  width: 90%;
}

.skills_python {
  width: 55%;
}

.skills_figma {
  width: 90%;
}

.skills_sketch {
  width: 85%;
}

.skills_photoshop {
  width: 80%;
}

.skills_close .skills_list {
  height: 0;
  overflow: hidden;
}

.skills_open .skills_list {
  height: max-content;
  margin-bottom: var(--mb-2-5);
}

.skills_open .skills_arrow {
  transform: rotate(-180deg);
}

/*==================== QUALIFICATION ====================*/

.qulification {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2);
}

.qualification_tabs {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-around;
}

.qualification_button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.qualification_button:hover {
  color: var(--first-color);
}

.qualification_icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.qualification_data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.qualification_title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.qualification_subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.qulification_calendar {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.qualification_rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--first-color);
  border-radius: 50%;
}

.qualification_rounder-last {
  position: relative;
  top: -16px;
}

.qualification_line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  transform: translate(6px, -7px);
}

/* .qualification [data-content] {
    display: none;
} */

.qualification_active [data-content] {
  display: block;
}

.qualification_button.qualification_active {
  color: var(--first-color);
}

/*==================== SERVICES ====================*/

.services_container {
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.services_content {
  position: relative;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  text-align: start;
}

.services_content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.services_icon {
  display: block;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

.servise_title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.services_button {
  cursor: pointer;
  font-size: var(--small-font-size);
}

.services_button:hover .button_icon {
  transform: translateX(0.25rem);
}

.services_modal {
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.services_modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.services_modal-services {
  row-gap: 1rem;
}

.services_modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.services_modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services_modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
}

/* Active Modal */

.active-modal {
  opacity: 1;
  visibility: visible;
}

.sroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/*==================== PORTFOLIO ====================*/

.portfolio_container {
  background: var(--body-color);
  justify-self: center;
  display: flex;
  width: 100%;
  background-color: var(--body-color);
}

.portfolio_container:nth-child(1) {
  margin-bottom: 6rem;
}

.portfolio {
  padding-bottom: 1rem;
}

.portfolio_project-container {
  display: flex;
  justify-content: space-between;
}

.portfolio_project-first {
  margin-bottom: 6rem;
}

.portfolio_project-second {
  margin-bottom: 6rem;
}

.portfolio_project-description {
  width: 40%;
}

.portfolio_content {
  padding: 0 1.5rem;
}

.portfolio_img {
  width: 265px;
  border-radius: 0.5rem;
  justify-self: center;
}

.portfolio_title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
}

.portfolio_description {
  margin-bottom: var(--mb-0-75);
}

.portfolio_project-links {
  display: flex;
  gap: 2rem;
  flex-direction: row;
}

.portfolio_button:hover .button_icon {
  transform: translateX(0.25rem);
}

.portfolio_project-description {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.portfolio_project-description > h1 {
  margin-bottom: var(--mb-0-75);
}

.portfolio_project-description > span {
  font-weight: bold;
  margin-bottom: var(--mb-0-25);
}

.portfolio_project-layers {
  display: flex;
}

.portfolio_project-player {
  display: flex;
  align-items: center;       /* Vertical center */
  justify-content: center;
}

.portfolio_button-view {
  font-size: var(--normal-font-size);
  margin-top: 0.5rem;
}

.portfolio_button-view:hover .button_icon {
  transform: translateX(0.25rem);
}

.project_first div:nth-child(1) {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 2;
}

.project_first div {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: flex-start;
}

.project_first div:nth-child(1) {
  z-index: 1;
}

.project_first div:nth-child(1) > img {
  max-width: 178px;
  max-height: 360px;
  transform: translate(10rem, 0rem);
}

.project_first div:nth-child(2) > img {
  max-width: 342px;
  max-height: 300px;
  transform: translate(0rem, 10rem);
}

.project_second div {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
}

.project_second div:nth-child(1) {
  z-index: 1;
}

.project_second div:nth-child(1) > img {
  max-width: 178px;
  max-height: 360px;
  transform: translate(10rem, -2rem);
}

.project_second div:nth-child(2) {
  align-items: center;
  justify-content: flex-end;
  z-index: -1;
}

.project_second div:nth-child(2) > img {
  max-width: 160px;
  max-height: 333px;
  transform: translate(8rem, -8rem);
}

.project_second div:nth-child(3) {
  align-items: center;
  justify-content: flex-end;
  z-index: -3;
}

.project_second div:nth-child(3) > img {
  max-width: 144px;
  max-height: 303px;
  transform: translate(-9rem, 0rem);
}

.project_third div {
  display: flex;
  justify-content: flex-end;
}

.project_third div:nth-child(1) {
  z-index: 2;
}

.project_third div:nth-child(2) {
  z-index: 1;
}

.project_third div:nth-child(1) > img {
  max-width: 178px;
  max-height: 380px;
  transform: translate(5rem, 0rem);
}

.project_third div:nth-child(2) > img {
  max-width: 160px;
  max-height: 342px;
}

/*==================== PROJECT IN MIND ====================*/

.project {
  text-align: center;
}

.project_bg {
  background-color: var(--first-color-second);
  padding-top: 3rem;
}

.project_title {
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-0-75);
}

.project_title,
.project_description {
  color: #fff;
}

.project_img {
  width: 232px;
  justify-self: center;
}

/*==================== TESTIMONIAL ====================*/

.testimonial_data,
.testimonial_header {
  display: flex;
}

.testimonial_data {
  justify-content: space-between;
  margin-bottom: var(--mb-1);
}

.testimonial_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: var(--mb-0-75);
}

.testimonial_name {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.testimonial_client {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.testimonial_desription {
  margin-bottom: var(--mb-2-5);
}

.testimonial_icon-star {
  color: var(--first-color);
}

.swiper-container .swiper-pagination-testimonial {
  bottom: 0;
}

/*==================== CONTACT ME ====================*/

.contact_container {
  text-align: start;
}

.contact_information {
  display: flex;
  margin-bottom: var(--mb-2);
}

.contact_icon {
  font-size: 2rem;
  color: var(--first-color);
  margin-right: var(--mb-0-75);
}

.contact_title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact_subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.contact_content {
  background-color: var(--input-color);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.25rem;
}

.contact_label {
  font-size: var(--smaller-font-size);
  color: var(--title-color);
}

.contact_input {
  width: 100%;
  background-color: var(--input-color);
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem 0.5rem 0;
}

.contact_form-submitted {
  text-align: center;
}

/*==================== FOOTER ====================*/

.footer {
  padding-top: 2rem;
}

.footer_conteiner {
  row-gap: 3.5rem;
}

.footer_bg {
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer_title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer_title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer_subtitle {
  font-size: var(--small-font-size);
}

.footer_links {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.footer_link:hover {
  color: var(--first-color-lighter);
}

.footer_social {
  font-size: 1.25rem;
  margin-right: var(--mb-1-5);
}

.footer_social:hover {
  color: var(--first-color-lighter);
}

.footer_title,
.footer_subtitle,
.footer_link,
.footer_social {
  color: #fff;
}

/*========== SCROLL UP ==========*/

.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: var(--first-color);
  opacity: 0.8;
  padding: 0 0.3rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

/* Show scroll */

.show-scroll {
  bottom: 5rem;
}

/*========== SCROLL BAR ==========*/

::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}

/*==================== MEDIA QUERIES ====================*/

/* For small devices */

@media (min-width: 360px) and (max-width: 479px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
  .nav-menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav_list {
    column-gap: 0;
  }
  .home_content {
    grid-template-columns: 0.25rem 3fr;
  }
  .home_data {
    grid-column: 1 / span 2;
  }
  .home_scroll {
    display: none;
  }
  .home_blob {
    width: 180px;
  }
  .home_img {
    justify-self: center;
    grid-column-start: 2;
    grid-row-start: 1;
  }
  .skills_title {
    font-size: var(--normal-font-size);
  }
  .qualification_data {
    gap: 0.5rem;
  }
  .services_container {
    grid-template-columns: max-content;
    justify-content: center;
  }
  .services_content {
    padding-right: 3.5rem;
  }
  .services_modal {
    padding: 0 0.5rem;
  }
  .project_img {
    width: 200px;
  }
  .home_content {
    grid-template-columns: 0.25rem 3fr;
  }
  .portfolio_project-description {
    width: 100%;
  }
  .portfolio_project-container {
    flex-direction: column;
  }
  .portfolio_project-description {
    margin-bottom: 3rem;
  }

  .portfolio_project-first,
  .portfolio_project-second {
    margin-bottom: 3rem;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
  .nav-menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav_list {
    column-gap: 0;
  }
  .home_content {
    grid-template-columns: 0.25rem 3fr;
  }
  .home_data {
    grid-column: 1 / span 2;
  }
  .home_scroll {
    display: none;
  }
  .home_blob {
    width: 180px;
  }
  .home_img {
    justify-self: center;
    grid-column-start: 2;
    grid-row-start: 1;
  }
  .skills_title {
    font-size: var(--normal-font-size);
  }
  .qualification_data {
    gap: 0.5rem;
  }
  .services_container {
    grid-template-columns: max-content;
    justify-content: center;
  }
  .services_content {
    padding-right: 3.5rem;
  }
  .services_modal {
    padding: 0 0.5rem;
  }
  .project_img {
    width: 200px;
  }
  .testimonial_data,
  .testimonial_header {
    flex-direction: column;
    align-items: center;
  }
  .testimonial_img {
    margin-right: 0;
    margin-bottom: var(--mb-0-25);
  }
  .testimonial_data,
  .testimonial_description {
    text-align: center;
  }
  .portfolio_project-container {
    flex-direction: column;
  }
  .portfolio_project-description {
    width: 100%;
  }
}

/* For medium devices */

@media (min-width: 541px) and (max-width: 767px) {
  .portfolio_project-container {
    flex-direction: column;
  }
  .home_content {
    grid-template-columns: 0.25rem 3fr;
  }
  .home_img {
    justify-self: center;
    grid-column-start: 2;
    grid-row-start: 1;
  }
  .home_data {
    grid-column: initial;
  }
  .home_img {
    order: 2;
    justify-self: center;
  }
  .home_scroll {
    display: none;
  }
  .skills_container,
  .portfolio_content,
  .project_container,
  .contact_container,
  .footer_container {
    grid-template-columns: repeat(2, 1fr);
  }
  .qualification_sections {
    display: grid;
    grid-template-columns: 6fr;
    justify-content: center;
  }
  .portfolio_project-container {
    flex-direction: column;
  }
  .portfolio_project-description {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  body {
    margin: 0;
  }
  .section {
    padding: 6rem 0 2rem;
  }
  .section_subtitle {
    margin-bottom: 4rem;
  }
  .header {
    top: 0;
    bottom: initial;
  }
  .header,
  .main,
  .footer_container {
    padding: 0 1rem;
  }
  .nav {
    column-gap: 1rem;
  }
  .nav_icon,
  .nav_close,
  .nav_toggle {
    display: none;
  }
  .nav_list {
    display: flex;
    column-gap: 2rem;
  }
  .nav_menu {
    margin-left: auto;
  }
  .change-theme {
    margin: 0;
  }
  .home_container {
    row-gap: 5rem;
  }
  .home_content {
    padding-top: 5.5rem;
    column-gap: 2rem;
  }
  .home_blob {
    width: 270px;
  }
  .home_sroll {
    display: block;
  }
  .home_sroll-button {
    margin-left: 3rem;
  }
  .qualification_tabs {
    justify-content: center;
  }
  .qualification_button {
    margin: 0 var(--mb-1);
  }
  .qualification_sections {
    grid-template-columns: 0.5fr;
  }
  .services_container {
    grid-template-columns: repeat(3, 218px);
    justify-content: center;
  }
  .services_icon {
    font-size: 2rem;
  }
  .services_content {
    padding: 6rem 0 2rem 2.5rem;
  }
  .services_modal-content {
    width: 450px;
  }
  .portfolio_img {
    width: 320px;
  }
  .project {
    text-align: initial;
  }
  .project_bg {
    background: none;
  }
  .project_container {
    background-color: var(--first-color-second);
    border-radius: 1rem;
    padding: 3rem 2.5rem 0;
    grid-template-columns: 1fr max-content;
    column-gap: 0.3rem;
  }
  .project_data {
    padding-top: 0.8rem;
  }
  .portfolio_project-container {
    flex-direction: row;
  }
  .footer_container {
    grid-template-columns: repeat(3, 1fr);
  }
  .footer_bg {
    padding: 3rem 0 3.5rem;
  }
  .footer_links {
    flex-direction: row;
    column-gap: 2rem;
  }
  .footer_socials {
    justify-self: flex-end;
  }
  .footer_copy {
    margin-top: 4.5rem;
  }
}

/* For large devices */

@media screen and (min-width: 1024px) {
  .header,
  .main,
  .footer_container {
    padding: 0;
  }
  .home_blob {
    width: 320px;
  }
  .home_social {
    transform: translateX(-6rem);
  }
  .services_container {
    grid-template-columns: repeat(3, 238px);
  }
  .portfolio_content {
    column-gap: 5rem;
  }
  .contact-form {
    width: 460px;
  }
  .contact_inputs {
    grid-template-columns: repeat(2, 1fr);
  }
  .portfolio_project-container {
    flex-direction: row;
  }
}
